import { useTranslation } from "react-i18next";

export const useServices = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("services.healthview.title"),
      description: t("services.healthview.description"),
      link: process.env.REACT_APP_HEALTHVIEW_URL,
      icon: "/assets/Healthview.png",
    },
    {
      title: t("services.cleverflow.title"),
      description: t("services.cleverflow.description"),
      link: process.env.REACT_APP_CLEVERFLOW_URL,
      icon: "/assets/CleverFlow.png",
    },
    {
      title: t("services.powerprescription.title"),
      description: t("services.powerprescription.description"),
      link: process.env.REACT_APP_POWERPRESCRIPTION_URL,
      icon: "/assets/PowerPrescription.png",
    },
    {
      title: t("services.businessinsight.title"),
      description: t("services.businessinsight.description"),
      link: process.env.REACT_APP_BUSINESSINSIGHT_URL,
      icon: "/assets/BusinessInsight.png",
    },
  ];
};
